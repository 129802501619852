.Techstack{
    width: 100%;
    /* height: 100vh; */
    background-color: #181818;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Techstack-heading{
    margin-top: 20vh;
    width: 90%;
    height: 10rem;
    /* background-color: white; */
}
.Techstack-heading p{
   font-weight: 250;
   font-family: 'DM Sans_18pt-ExtraLight' ;
    font-size: 30px;
    margin-top: 1.5rem;
    width: 100%;
    color: #FFFFFF;
    line-height: 40px;
}
.Techstack-heading h1{
    color: white;
    font-size: 55px;
    font-weight: 500;
}


.Techstack-skills{
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;
    gap: 2.2rem;
    margin-top: 4rem;
    width: 90%;
    height: 70%;
    margin-bottom: 10vh;
    /* background-color: white; */
}
.skills{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 90px;
    position: relative;
    overflow: hidden;
    /* border-radius: 20px; */
    background-color: #181818;
    overflow: hidden;

}
.skills::before{
    /* border-radius: 20px; */

    content: '';
    background-image: linear-gradient(rgb(210, 92, 2)
,rgb(255, 117, 11)
);
    height: 60%;
    width: 220%;
    position: absolute;
    animation: lineanim 7s linear infinite;
   
}

.skills::after{
    content: '';
    position: absolute;
    background-color: #181818;
    inset: 1px;
    /* border-radius: 20px; */

}

@keyframes lineanim {
    from{
        transform: rotate(0deg);
    }to{
        transform: rotate(360deg);
    }
    
}



.skills img{
    width: 25%;
    z-index: 1;
}

.viewmorebtn-techstack{
    font-family: "DM Sans18ptLight";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 40px;
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    background-color: transparent;
    font-weight: 500;
    color: white;
}
.view-more {
    display: flex;
    justify-content: center;
    margin-top: 1rem; /* Adjust margin as needed */
    margin: 0 auto;
    width: 90%;
    margin-bottom: 3vh;
}
  