.progress {
  position: fixed;
  bottom: 3%;
  right: 3%; /* Adjusted to start from the left */
  height: 70px;
  width: 70px;
  display: none;
  place-items: center;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000;
}

.progress-value {
  display: block;
  height: calc(100% - 3px);
  width: calc(100% - 3px);
  background: linear-gradient(to left, #181818 0%, rgb(0, 0, 0) 0%); /* Reverse the gradient direction */
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 35px;
  color: #000000;
}


.custom-upwardarrow {
  font-size: 3rem !important; /* Adjust the font size to increase the arrow size */
  color: white; 
}
