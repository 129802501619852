.marquee {
  --gap: 3rem;
  display: flex;
  overflow: hidden;
  align-self: center;
  user-select: none;
  gap: var(--gap);
  background-color: #181818;
  color: white;
  padding: 0.4rem 0px;
  list-style: none;
  background-color: #ffffff;
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 3rem));
  }
}

.scrollWala {
  animation: scroll 90s linear infinite;
}
