.Footer{
    min-width: 100%;
    /* height: 80vh; */
    background-color: #181818;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Footer-list-content{
    display: flex;
    justify-content: space-between;
    width: 90%;
    height: 80%;
    /* background-color: white; */
    margin-top: 3rem;
}
.footer-social-icons{
    width: 30%;
    color: white;
}

.footer-links{
    display: flex;
    justify-content: space-between;
    width: 60%;
    height: 100%;
    /* background-color: white; */
}
.address-heading{
    margin-bottom: 1.4rem;
}
.address-paragraph{
    margin-bottom: 15px;
}
.footer-Address{
    color: white;
    margin-left: 4.5rem;
    
}
.footer-Address h3{
    font-size: 26px;

}
.footer-Address p{
    width: 90%;
    font-family: "DM Sans18ptLight";
    font-size: 22px;
    font-weight: 400;
}
.footer-social-icons h3{
    font-size: 26px;

}
.footer-social-icons p{

    font-size: 20px;
    margin-top: 1rem;
    
    
}
.footer-social-icons p:hover{
    cursor: pointer;
}
.connect-dewebbox{
    display: flex;
    justify-content: space-between;
    width: 90%;
    height: 80%;
}
.connect-dewebbox a{
    
color: #F17C24;
font-size: 50px;
margin-top: 10px;
font-weight: 400;
font-family: Salgesis !important;

}
.connect-dewebbox h2{
    color: rgb(255, 255, 255);
    font-size: 40px;

}

.footer-para{
    width: 90%;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
    color: rgb(188, 187, 187);
    
}
.footer-para p{
    text-align: center;
    font-family: 'DM Sans_18pt-ExtraLight';
    font-size: 20px;
    margin: 2rem 0rem;
    font-weight: 400;

}
.footer-span{
    font-size: 20px;
    color: rgb(188, 187, 187);
    margin-left: 5px;
    font-family: 'DM Sans_18pt-ExtraLight';
    font-weight: 400;
}
.social-heading h3{
    font-size: 26px;
    font-family: 600;
    margin-bottom: 1.5rem;
}

.social-links p{
    font-family: "DM Sans18ptLight";
    font-weight: 400;
    font-size: 20px;
    margin-top: 0.5rem !important;
}
.WhatsAppIcon-FooterImg{
    margin-top: 5px;
}
.arrow-heading{
    display: flex;
    align-items: center;
    
}
.arrow-heading a{
    text-decoration: none;
    color: #F17C24;
}
.LocationIcon-img{
    cursor: pointer;
}
.mobile-number{
    margin-top: 1rem !important;
}
.mobile-number a, .mobile-number-second a{
    text-decoration: none;
    font-family:"DM Sans18ptLight";
    color: white;
}

.straight-line-footer{
    margin-top: 1rem;
    width: 90%;
    height: 1px;
    background-color: #ffffff1d;
}
.collab-section h2{
    font-family: "DM Sans";
    font-size: 50px;
    font-weight: 500;
    
}