.Bgimage {
    width: 100%;
    height: 100vh;
    background-color: #181818;
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  .letsgettowork{
    display: flex;
    align-items: center;
  }
  
  .bgimagesection {
    margin-top: 10vh;
    width: 90%;
    height: 70vh;
    background-image: url('../../../Assets/Images/BackgroundImage.png');
    background-size: cover;
    background-position: center 30.0979px;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Background image fixed */
    position: relative;
  }
  
  .bgimagesection img {
    height: 100%;
    width: 100%;
    object-fit: cover; /* Ensure image covers entire area */
  }
  /* If you want to style the content inside bgimagesection, you can add styles here */
  .bgimagesection .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* Example styling for text color */
  }
  .bgcontentsection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 90%;
    height: 60%;
    background-color: #101010;
    left: 0;
    /* bottom: 10px; */
    top: 200px;
    z-index: 100;
    padding: 0px 60px;
  }
  .bgcontentheading h1{
    color: white;
    font-size: 55px;
    font-weight: 600;
    
  }
  .bgcontentheading span{
    color: #F17C24;
    font-family: 'Salgesis' !important;
    font-size: 55px;
    font-weight: 400;
  }
  .bgcontentheading{
    width: 80%;
   
  }
  
  .bgcontentbtn{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
  }

  .CircleStar-img:hover{
    display: none;
  }
  
  .get-in-touch-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    transition: all 0.5s;
    width: 1%;
    height: 1%;
    border-radius: 50%;
    opacity: 1;
  }

  .get-in-touch-content:hover{
    background-color: #F17C24;
    color: #101010;
    width: 102%;
    height: 102%;
  }

  .get-in-touch-content p{
    font-size: 20px;
    font-family: "DM Sans_18pt-ExtraLight";

  }
  .bgcontentbtn img:hover{
    opacity: 0;
  }

  .bgcontentbtn:hover{
    /* background-color: #F17C24; */
    cursor: pointer;
    color: white;
  }
  .bgcontentbtn p{
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 400;
  }