.main-dev {
    display: flex;
    width: 100%;
    min-height: 85vh;
    background-color: #181818;
  }
  
  .main-right {
    width: 100%;
    min-height: 100%;
  }
  
  .main-left {
    position: relative;
    width: 100%;
    min-height: 95vh;
  }
  
  .heading-section {
    position: absolute;
    left: 5%;
    width: 65%;
    height: 100%;
    color: white;
    z-index: 100;
  }
  
  .video-section {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50% 0px 0px 50%;
  }
  
  .video-section .dwb-video {
    height: 100%;
    background-size: cover;
    object-fit: cover;
    border-radius: 50% 0px 0px 50%;
  }
  
  .heading-section h1 {
    line-height: 5px;
    margin-top: 5rem;
  }
  
  .heading-section .heading-one,
  .heading-section .heading-three {
    font-weight: 500;
    margin-top: 5rem;
    font-size: 115px;
    line-height: 5rem;
  }
  
  .heading-two {
    font-size: 32px;
    background: linear-gradient( #FF9F00, #FFC600);
    -webkit-background-clip: text;
    -webkit-text-fill-color: #FF9F00;
    background-size: 100%; 
    font-family: 'Salgesis' !important;
    z-index: 10;
  }
  
  
  .main-video-wrapper{
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    background-color: #00000022;
    border-radius: 50% 0px 0px 50%;
  }