.Clientreview {
    width: 100%;
    height: 110vh;
    background-color: #181818;
    display: flex;
    padding-top: 10vh;
}

.reviewleft {
    width: 50%;
    height: 100%;
    padding: 50px;
    color: white;
    /* background-color: rgb(142, 255, 217); */
}
.reviewleft-firstbox h2{
    font-size: 51px;
    margin: 30px 0px;
    font-family: "DM Sans";
    font-weight: 500;
    line-height: 53px;
    
}
.line-inside-review{
    width: 94%;
    height: 0.8px;
    background-color: #ffffff40;
}
.reviewleft-firstbox h6{
    margin: 20px 0px;
    margin-top: 50px;
    font-size: 24px;
    font-family: "DM Sans18ptLight";
    line-height: 10px !important;
    font-weight: 400;

}
.reviewleft-firstbox p{
    margin: 20px 0px;
    font-size: 24px;
    font-weight: 250;
    font-family: "DM Sans_18pt-ExtraLight";
    width: 90%;
}
.reviewleft-secondbox{
    width: 80%;
    display: flex;
    color: white;
    justify-content: space-between;
    margin: 20px 0px;
}
.tenkuser span{
    font-family: "DM Sans";
}
.hundredproject span{
    font-family: "DM Sans";
}
.tenkuser h1{
    font-size: 51px;
    font-family: "DM Sans";
    font-weight: 500;

}
.tenkuser p{
    font-size: 24px;
    font-family: "DM Sans18ptLight";
    font-weight: 400;
}
.hundredproject h1{
    font-size: 51px;
    font-family: "DM Sans";
    font-weight: 500;
}
.hundredproject p{
    font-size: 24px;
    font-weight: 400;
    font-family: "DM Sans18ptLight";

    width: 80%;
}
.reviewright {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    /* background-color: rgb(211, 124, 254); */
    overflow: hidden;

}

.reviewscollar {
    width: 100%;
    height: 85%;
    background-color: #181818;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 20px 0px;
    overflow: hidden;
}

.reviews {
    padding: 1rem 2rem;
    width: 80%;
    /* min-height: 40vh; */
    background-color: #222222;
    animation: scrollAnimation 20s linear infinite;
}

.reviews-para{
    display: flex;
    flex-direction: column;
}
.reviews-para p{
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 250;
    font-family: "DM Sans_18pt-ExtraLight";
    line-height: 25px;
    margin-bottom: 5px;
}



.user-reviews{
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    color: white;
}
.user-reviews h6{
    font-family: "DM Sans";
    font-size: 23px;
    font-weight: 500;
}
.user-reviews p{
    font-size: 16px;
    font-family: "DM Sans";
    font-weight: 400;
    margin-top: 2px;
}
.user-reviews img{
    width: 73px;
    margin-right: 0.6rem;
}


@keyframes scrollAnimation {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(calc(-100% * 3)); /* Adjust this value based on the number of reviews you have */
    }
}
