.Ourclients-home{
    width: 100%;
    height: 132vh;
    background-color: #181818;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2vh
    
    
;
}
.Ourclients-heading-first h2{
    color: white;
    font-size: 50px;
    font-weight: 500;
}
.Ourclients-home-heading{
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    height: 34vh;    
    
}
.Ourclients-heading-btn button{
    border: none;
    background-color: #181818;
    border: 2px solid white;
    color: white;
    height: 50px;
    border-radius: 50px;
    cursor: pointer;
}
.Ourclients-home-list{
    width: 100%;
    height: 70%;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0% 8%;
    padding: 3vh 10vh 1vh 10vh;
    margin-top: 2vh;
    background-color: #ffffff;
    
}

.clients{
    width: 200px;
    height: 10px;
}

.clients img{
    width: 176px;
}