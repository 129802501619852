.marqueeTwo {
  width: 101%;
    --gap: 1rem;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    background-color: #181818;
    color: white;
    transform: rotate(-4.1deg);
    overflow: hidden;
  padding: 1.4rem 0px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    
  }
  .webdevmarquee{
    /* margin-left: 50px !important; */
  }
  .marquee__content li{
    margin-left: 20px !important;
  }
  
  .marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    min-width: 100%;
    /* gap: 4.5rem */
  }
  .marquee__content li{
    font-size: 2.2rem;
    font-weight: 500;
  }
  
  @keyframes scrollMarkTwo {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }
  .scrollMarkTwo{
    animation: scrollMarkTwo 20s linear infinite;
  }

@media only screen and (max-width: 700px) {

  .marquee__content li{
   margin-left: 12px !important;
  }

.marqueeTwo{
  overflow: hidden;
  transform: rotate(-3.5deg);

}
}
