.marqueeClients {
    --gap: 1rem;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    background-color: #181818;
    color: #FFFFFF;
    padding: 0rem 0px;
    width: 100% !important;
  }

  .marquee__contentclients h1{
    font-family: "Salgesis";
    font-size: 128px;
    font-weight: 500;
  }

  .stroke-heading{
    -webkit-text-stroke: 0.6px #FFFFFF;
    text-stroke: 0.6px #FFFFFF; 
    color: transparent;
    font-weight: 400;
    margin-left: 30px;
    margin-right: 30px;
  }
  .marquee__contentclients {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    min-width: 100%;
    gap: var(--gap);
  }
  @keyframes scrollThree {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }
  
  .marquee__contentclients {
    animation: scrollThree 15s linear infinite; /* Adjust the duration */
  }
  

 
.Clientslistpage{
  background-color: rgb(255, 255, 255);
  width: 100%;
  /* height: 400vh; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

}
.clientsimagesection{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 92%;
  height: 90%;
  /* background-color: azure; */
}
.clientsimagesection-imgBox{
  /* background-color: #181818; */
  width: 22%;
}
.clients-page-image{
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 10vh;
  /* background-color: chocolate; */
  width: 95%;
  gap: 5% 5%;
  display: flex;
  flex-wrap: wrap;
}
.client-img{
  margin-bottom: 6vh;
  width: 20%;
}

@media only screen and (max-width: 700px) {
  .Clientslistpage{
    overflow: hidden;
   /* height: 600vh; */
  }
  .clientsimagesection-imgBox img{
   width: 100%;
  }
  .clientsimagesection{
    width: 100%;
  }

  

  .clientsimagesection-imgBox{
   width: 45%;
  }


  .marquee__contentclients h1{
    font-size: 28px;
    font-weight: 400;
  }

  .client-img img{
   width: 90%;
  }
  .client-img{
    width: 40%;
  }

 
  .client-img {
   margin-bottom: 3vh;
  }


}
