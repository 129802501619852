*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Epilogue", sans-serif;
}
html,body{
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}
/* Custom Scroll bar */
::-webkit-scrollbar{
  width: 5px;
}
::-webkit-scrollbar-track{
  /* background: #d1e5ff; */
}
::-webkit-scrollbar-thumb{
  /* border-radius: 20px; */
  border-radius: 10px;
  height: 150px;
  background: linear-gradient(#ffa722,#ffa811);
}


.active{
  font-size: 24px;
  font-weight: 500;
  background: -webkit-linear-gradient(rgba(227, 142, 0, 1), rgba(255, 73, 121, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }

@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: Salgesis;
  src: url(../src/Assets/fonts/salgesis-font/Salgesis-rgPA9.otf);
}

@font-face {
  font-family: DM SansBlack;
  src: url(../src/Assets/fonts/DM_Sans/static/DMSans-Black.ttf);
}
@font-face {
  font-family: DM Sans18ptLight;
  src: url(../src/Assets/fonts/DM_Sans/static/DMSans_18pt-Light.ttf);

}


@font-face {
  font-family: DM Sans_18pt-ExtraLight;
  src: url(../src/Assets/fonts/DM_Sans/static/DMSans_18pt-ExtraLight.ttf);
}
@font-face {
  font-family: DM Sans;
  src: url(../src/Assets/fonts/DM_Sans/static/DMSans-Medium.ttf);
}
@font-face {
  font-family: DM BlackBold;
  src: url(../src/Assets/fonts/DM_Sans/static/DMSans-Bold.ttf);
}

/*   Cursor Code   */
.custom-cursor {
  position: fixed;
  width: 20px;
  height: 20px;
  background-color: #F17C24;
  border-radius: 50%;
  pointer-events: none; /* Ensure the cursor doesn't interfere with click events */
  z-index: 9999; /* Ensure it appears on top of everything */
  transition: all 10ms ease-out;
}
.typo-links{
  font-family: "Epilogue", sans-serif !important;

}
.typo-links:hover{
  cursor: pointer;
  color: #F17C24;
}

/* .typo-links:hover {
  cursor: pointer;
  background: linear-gradient(90deg, #F17C24 0%, #FFD479 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

@media only screen and (max-width: 700px) {

  ::-webkit-scrollbar{
    width: 0px;
  }

  .css-nv47zx-MuiGrid-root>.MuiGrid-item {
    /* padding-left: 0px !important; */
  }

  .css-nv47zx-MuiGrid-root>.MuiGrid-item {
    padding-top: 0px !important;
}
  .custom-cursor{
    display: none;
  }

  
  @media (max-width: 768px) {
    .swiper-slide-section {
      width: 65% !important;
    }
  }

  .menu-button-div{
    display: none;
  }

  .navbar{
    padding: 0px 30px !important;
  }
.nav-menu-inside-links{
  display: block !important;
}
  .main-dev{
   min-height: 85vh !important;
  }
  .main-left{
    min-height: 10vh !important;
  }

  .heading-section {
  left: 8% !important;
  }


  .heading-section .heading-one,
  .heading-section .heading-three {
    font-family: "Epilogue", sans-serif;
    font-size: 40px !important;
    margin-top: 2rem !important;
    font-weight: 400;
    width: 110% !important;
    color: rgba(255, 255, 255, 1);
    
  }
  .heading-section h1{
    font-size: 50px !important;
    line-height: 3rem !important;
    margin-top: 1rem !important;
  }
  .video-section .dwb-video{
    /* height: 50% !important; */
    border-radius: 0% !important;
    /* margin-top: 15rem !important; */
  }
    .main-video-wrapper{
      height: 50% !important;
      border-radius: 0% !important;
      margin-top: 15rem !important;
    }

  .animation-section {
    height: 40vh !important;
    gap: 0.9rem !important;
  }
    

  .marquee__content li{
    font-size: 22px !important;
  }
  .nav-links{
    display: none !important;
  }
  .drawer-links {
     width: 100vw !important;
  }

  .webdev{
    flex-direction: column;
    height: 90vh !important;
    margin-bottom: 10px !important;
  }
  .webdevheading{
    width:  100% !important;
    height: 90% !important;
  }
  
.webdevimage {
width: 100% !important;
}
.webdevheading p{
  width: 80% !important;
  font-size: 16px !important;
  font-family: 'DM Sans_18pt-ExtraLight' ;
  font-weight: 250;
  margin: 20px 30px !important;
}
.webdevheading button{
  font-size: 12px !important;
  font-family: "DM Sans";
  margin: 10px 30px !important;
  
}
.webdevheading h1{
  margin: 0px 30px !important;
  margin-top: 70px !important;
  font-size: 24px !important;
  font-weight: 500 !important;
}

.webdevheading button{
  width: 140px !important;
  height: 40px !important;
}

  .progress {
    height: 55px !important;
    width: 55px !important;
    right: 5% !important;
    bottom: 15% !important;
  }
  .Homeportfolio-crousel {
    padding-left: 30px;
height: 80vh !important;
  }
  .portfolio-heading{
    margin-left: 0vh !important;
  }

  .swiper-main{
    padding-left: 0% !important;
  }

  .All-Projects-Btn{
    margin-left: 0vh !important;
  }
  .portfolio-btn-btn{
    margin-left: 10% !important;
  }
  .bgcontentsection{
    top: 50px !important;
  }

  .portfolio-heading h1{
    
    font-size: 32px !important;
    font-weight:  500 !important;
  }


.ourservice-paragraph p{
  width: 100% !important;
  font-size: 20px !important;
}
.ourservice-btn button{
  width: 140px !important;
  height: 35px !important;
  font-size: 12px !important;
  font-family: "DM Sans" !important;

}

.ourservice-heading-first h2{
  font-size: 28px;
}


  .Ourclients-home {
height: 140vh !important;
padding-bottom: 0vh !important;
  }
  .Ourclients-home-list{
  gap: 1% 15% !important;
    transition: all 0.5s ease; /* Transition effect */

  
  }
  .clients img {
width: 100px !important;

    }

.clients {
width: 112px !important;
height: 60px !important;
}

.Ourclients-heading-first h2{
  font-size: 28px !important;
}
.Ourclients-home-list {
  height: 75% !important;
  padding: 3vh 5vh 1vh 5vh !important;
}
.Ourclients-home-heading{
  height: 20vh !important;  
  padding-top: 0vh !important;  
}
.Ourclients-heading-btn button{
  width: 152px !important;
  height: 40px !important;
  font-size: 10px !important;
}
.LocationIcon-img img{
  width: 60% !important;
}
.WhatsAppIcon-FooterImg img{
  width: 44% !important;
}
.WhatsAppIcon-FooterImg {
  margin-bottom: 10px;
}
  .Clientreview {
    padding-left: 16px !important;
    height: 100vh !important;
    flex-direction: column;
    padding-top: 0vh !important;
  }
  .reviews {
    min-height: 52vh !important;
    padding: 1rem 1rem !important;
    width: 90% !important;
  }
  .reviewleft-secondbox {
    width: 90% !important;
  }

  
  .reviewleft-firstbox h2 {
   font-size: 24px !important;
   width: 90%;
  line-height: 35px !important;
  }
  
  .reviewleft-firstbox h6{
    font-size: 14px !important;
  }
  .reviewleft-secondbox h1{
    font-size: 28px !important;
  }
  .reviewleft-secondbox p{
    font-size: 16px !important;
  }
  .line-inside-review{
    width: 75% !important;
   
}

  .reviewleft-firstbox p{
    font-size: 16px !important;
  }
  .reviewleft{
   
     width: 100% !important;
     padding: 20px !important;
     height: 60% !important;
  }
  .reviews-para p{
    font-size: 16px !important;
  }
  .reviews-para{
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .user-reviews img{
    width: 59px !important;
  }
  .user-reviews h6{
    font-size: 16px !important;
  }
  .user-reviews p{
    font-size: 14px !important;
    font-weight: 400;
  }
  
  .reviewright{
    width: 100% !important;
    height: 60% !important;
  }
  .firstheading-portfolio {
font-size: 18px !important;
  }

  .Techstack-heading {
    margin-top: 6vh !important;
  }
  .Techstack-skills{
    margin-bottom: 0vh !important;
  }

  .Techstack-heading h1{
    margin-top: 1rem !important;
    font-size: 40px !important;
  }
  .Techstack{
    padding-left: 20px;
    /* height: 130vh !important; */
  }
  .Techstack-heading p {
     margin-top: 1rem !important;
    
  }
  .Techstack-skills {
    justify-content: center !important;
margin-top: 3rem !important;
gap: 1.8rem !important;

  }
  .skills {
     width: 138px !important;
     height: 70px !important;
  }
  .Techstack-heading p {
    font-size: 16px !important;
    font-family: 'DM Sans_18pt-ExtraLight' ;
    line-height: 1.2rem !important;
  }
  .Blogs-section {
padding-left: 16px !important;
height: 200vh !important;

    }
    .blog-heading-section {
       margin-top: 5vh !important;
    }

    .blog-content{
      flex-direction: column;
      height: 80% !important;
    }
.blog-one {
width: 100% !important;
height: 50% !important;
padding: 0.4rem;
}
.blog-two{
  width: 100% !important;
  height: 50% !important;
  margin-bottom: 5rem;
  padding: 0.4rem;

}
.blog-heading h1{
  font-size: 42px !important;
}
.blog-one-img{
  width: 100% !important;
  height: 60% !important;
}
.blog-one-content h3{
  font-size: 16px !important;
  margin: 5px 5px !important;
  font-weight: 500;
  width: 100% !important;
}
.blog-one-content{
  padding: 0.5rem;
}
.blog-inside{
  flex-direction: column;
}
.blog-one-content p {
margin: 10px 5px !important;
}
.blog-one-content button{
  margin: 1rem 0rem !important;
}
.blog-button button {
  width: 150px !important;
  height: 42px !important;
  font-size: 16px !important;
  font-weight: 500;
  font-family: "DM Sans18ptLight";

}



.Bgimage {
height: 75vh !important;
}


.bgcontentsection {
  text-align: center;
flex-direction: column;
height: 70% !important;
width: 100% !important;
padding: 20px 20px !important;
justify-content: space-around !important;
}
.letsgettowork{
  justify-content: center;
}
.bgcontentsection{
  font-size: 28px !important;
}
.bgimagesection{
  display: none;
  /* width: 90% !important;
  height: 400px !important;
  background-size: cover;
  object-fit: cover; */
}

.bgcontentheading{
  margin-top: 5vh;
  width: 90% !important;
}
.bgcontentheading h1{
  font-size: 28px !important;
}
.bgcontentheading span {
  font-size: 28px !important;
}


.Footer{
  /* background-color: #181818; */
  /* height: 70vh !important; */
}
.footer-Address p {
  font-family: 'DM Sans_18pt-ExtraLight' !important;
  font-size: 16px !important;
  width: 100% !important;
}
.footer-social-icons{
  margin-top: 2rem;
  width: 100% !important;
}

.footer-Address{

  margin: 0rem !important;
  width: 100%;
}
.footer-links {
  width: 100% !important;
}
.Footer-list-content{
  flex-direction: column-reverse !important;
  height: 15% !important;
  margin-top: 0px !important;
}
.Footer-logo{
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.social-links{
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-links p a{
  font-family: 'DM Sans_18pt-ExtraLight' !important;
  font-size: 14px !important;
  margin-right: 10px;
}
.social-links p:hover{
  cursor: pointer;
}
.address-heading{
  margin-bottom: 0.7rem !important;
  text-align: center;
}
.social-heading h3{
  text-align: center !important;
  margin-bottom: 0.7rem !important;
}
.collab-section h2{
  text-align: center !important;
}

.footer-links{
  text-align: center;
  flex-direction: column-reverse !important;
  height: 40% !important;
}
.connect-dewebbox h2{
  margin-top: 12px !important;
  font-size: 24px !important;
}
.connect-dewebbox a{
  font-size: 24px !important;
}
.connect-dewebbox{
  height: 10% !important;
}


.footer-para p{
  font-size: 12px !important;
  margin: 0px !important;
  margin-top: 4vh !important;
}
.footer-para span{
  font-size: 12px !important;
}
.arrow-outward-icon{
  font-size: 40px !important;
  margin-top: 0px !important;
}


.straight-line-footer{
  margin-top: 1rem !important;

}

}




.cursor {
  position: fixed;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  z-index: 10000;
  border: 10px solid  #F17C24;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  transition: all 100ms ease-out;
  z-index: 10000000;
} 

.cursor-pointer {
  position: fixed;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  background-color: transparent;
  z-index: 10000;
  height: 5rem;
  width: 5rem;
  z-index: 10000000;

  transition: all 100ms ease-out;
}
@media only screen and (max-width: 600px) {
  .cursor-pointer,
  .cursor {
    display: none;
  }
}
