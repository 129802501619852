


.marqueeTeam {
  --gap: 0.2rem;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  background-color: #181818;
  color: #FFFFFF;
  padding: 0rem 0px;
  width: 100% !important;
}


.marquee__contentTeam  {
  flex-shrink: 0;
  display: flex;
  /* justify-content: space-around; */
  min-width: 100%;
  gap: var(--gap);
}

.marquee__contentTeamTwo{
  flex-shrink: 0;
  display: flex;
  /* justify-content: space-around; */
  min-width: 100%;
  margin-top: 9vh;
  margin-bottom: 10vh;
  gap: var(--gap);
}
@keyframes scrollTeam {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@keyframes scrollTeamtwo {
  from {
    transform: translateX(calc(-100% - var(--gap)));
  }
  to {
    transform: translateX(0);
  }
}

.marquee__contentTeam  {
  animation: scrollTeam 37s linear infinite; /* Adjust the duration */
}

.marquee__contentTeamTwo  {
  animation: scrollTeamtwo 40s linear infinite; /* Adjust the duration */
}


.marquee__contentTeam li{
  list-style: none;
}
.marquee__contentTeamTwo li{
  list-style: none;
}




.Aboutusbgimage{
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Aboutusbgimage video{
  background-size: cover;
  background-position: center;
  
}

.AboutUsBgimageDescription{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 20%;
  }
  .AboutUsBgimageDescription p{
    text-align: center;
    font-size: 60px;
    color: #FFFFFF;
    margin-top: 5%;
    font-family: "Epilogue";
  }
  .AboutUsBgimageDescription h1{
    display: flex;
    align-items: center;
    font-size: 64px;
    font-family: "Salgesis";
    color: #FFFFFF;
  margin-top: 30px;
  }
  .AboutUsBgimageDescription span{
    color: #ff973b;
  }
.about-us-video{
  width: 100%;
}
.WeDream{
  color: #FFFFFF !important;
  font-weight: 700;
  font-family: "Epilogue";
}
.WeDesign{
  color: rgb(255, 177, 52) !important;
  font-weight: 700;
  font-family: "Epilogue";

}
.WeDigitize{
  color: #ff973b !important;
  font-weight: 700;
  font-family: "Epilogue";

}


  @media only screen and (max-width: 700px) {
    .Aboutusbgimage{
      height: 40vh;
      overflow: hidden;
    }
    .AboutUsBgimageDescription p {
      font-size: 22px;
      font-weight: 500;
    }
    .about-us-video{
      width: 160%;
    }
    .AboutUsBgimageDescription h1 {
      font-size: 33px;
    }

    .marquee__contentTeam  img{
      width: 70% !important;
    }

    .marquee__contentTeamTwo  img{
      width: 70% !important;
    }
  
    .marquee__contentTeam li{
      margin-left: -60px !important;
      margin-right: -60px !important;
    }
    .marquee__contentTeamTwo{
     margin-top: 5vh !important;
    }

    .marquee__contentTeamTwo li{
      margin-left: -60px !important;
      margin-right: -60px !important;
    }

  }
  