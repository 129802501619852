.Contactusheading{
    width: 100%;
    height: 50vh;
    background-color: #181818;
}
.Contactusdesc{
    width: 92%;
    height: 90%;
    /* background-color: white; */
    margin: 0 auto;
    color: white;
}
.Contactusparagraph{
    display: flex;
    align-items: center;
    padding-top: 15vh;
}
.Contactusparagraph p{
    font-size: 20px;
    font-weight: 250;
    font-family: "DM Sans_18pt-ExtraLight";

}
.Contactusdot{
    width: 11px;
    height: 11px;
    background-color: #FFFFFF;
    border-radius: 50%;
    margin-right: 10px;
}
.ContactusHeading{
    font-size: 68px;
    font-weight: 600;
    margin-top: 5vh;
}

.Contactusform{
    position: relative;
    width: 100%;
    height: 90vh;
    background-color: #181818;
}
.contactusbanner{
    width: 100%;
    height: 100%;
}
.contactusbanner img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}


.Workwithus{
    width: 100%;
    /* height: 140vh; */
    background-color: #181818;
}
.workwithusbannersection{
    width: 92%;
    /* height: 40vh; */
    /* background-color: #FFFFFF; */
    color: white;
    margin: 0 auto;
    padding-top: 15vh;
}
.workwithusinfoheading h1{
   font-size: 70px;
}
.workwithusinfoheading p{
    font-family: "DM Sans_18pt-ExtraLight";
    font-size: 24px;
    width: 80%;
    margin-top: 3vh;
}
.job-role-heading{
    margin-top: 12vh;
}
.job-role-heading h1{
   font-size: 48px;
}

.job-role-section{
    margin-top: 3vh;
    display: flex;
    flex-wrap: wrap;
    gap: 5vh;
    width: 100%;
    /* background-color: #FFFFFF; */
}
.job-section{
    padding: 5vh;
    width: 340px;
    height: 340px;
    background-color: #1D1D1D;
    /* border: 1px solid #FFFFFF;
     */
}
.job-section h2{
    font-size: 24px;
}
.job-section p{
    font-size: 18px;
    margin-top: 3vh;
    font-family: "DM Sans_18pt-ExtraLight";
    font-weight: 250;

}


.GetistouchContactus{
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: #b9a9ff13; */
    top: 0;
}

.contact-info{
    width: 50%;
    height: 100%;
    /* background-color: white; */
    padding: 20vh 10vh;
}

.contact-info-heading-first{
    font-size: 51.25px;
    color: white;
    font-weight: 600;
}
.contact-info-heading-second{
    font-size: 51.24px;
    color: white;
    font-family: "Salgesis";
    font-weight: 500;
}


.contact-info-paragraph{
    font-size: 22.98px;
    margin-top: 3vh;
    color: white;
    font-family: "DM Sans_18pt-ExtraLight";
    font-weight: 250;
   width: 80%;
}
.contact-info-heading-third{
    margin-top: 3vh;
    font-size: 35.35px;
    font-weight: 600;
    color: #F17C24;
}

.contact-from{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    /* background-color: white; */
}

.contact-inputs-section{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 85%;
    /* background-color: #F17C24; */
}




@media only screen and (max-width: 700px) {

.ContactusHeading {
  font-size: 24px;
}
.Contactusparagraph {
padding-top: 8vh;
}

.Contactusheading{
    height: 28vh;
}
.GetistouchContactus {
flex-direction: column;
}
.contact-info {
width: 100%;
height: 50%;
}
.contact-from {
    width: 100%;
    height: 170vh;
}
.contact-inputs-section {
width: 90%;
height: 95%;
}
.contact-info-paragraph {
    font-size: 15px;
    width: 90%;
}

.Contactusform {
height: 110vh;
}

.contact-info{
    padding: 3vh 3vh;
}
.workwithusinfoheading p {

font-size: 15px;
width: 90%;
}
.workwithusinfoheading h1 {
font-size: 24px;
}
.workwithusbannersection {
padding-top: 10vh;
}
.job-role-heading h1 {

font-size: 24px;
}

.Workwithus {
/* height: 160vh; */
}
.contact-info-heading-second {
font-size: 24px;
}
.contact-info-heading-first {
font-size: 24px;
}

.contact-info-heading-third {
font-size: 28px;
}
.job-section{
    width: 320px;
    height: 320px;
    padding: 3vh;

}
.contactusbanner {
 height: 95%;
}
}


