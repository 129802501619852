.preloader{
    height: 100vh;
    width: 100%;
    background-color: #000;
    z-index: 1000;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 555;
    display: flex;
    justify-content: center;
    align-items: center;
}



.preloader .texts-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 40%;
    font-size: 20px;
    font-weight: 800;
    overflow: hidden;
    fontFamily:"Epilogue",

    /* color: rgb(136, 255, 68); */
}

.Dream{
    font-size: 40px;
    color: white;
  }
  .Design{
    font-size: 40px;
    color: #ffbb00;
  }
  .Digitize{
    font-size: 40px;
    color: rgba(241, 124, 36, 1);
  }


  @media only screen and (max-width: 700px) {
    .preloader .texts-container{
width: 80%;
    }

    .Dream{
      font-size: 22px;
    
    }
    .Design{
      font-size: 22px;
      
    }
    .Digitize{
      font-size: 22px;
      
    }
  

  }


