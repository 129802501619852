.nav-links{
    display: flex;
    justify-content: space-between;
    margin-right: 20%;
    /* background-color: blueviolet; */
}



.nav-menu-inside-links{
    display: none;
}

.logo{
    
}
.nav-menu-btn{
    display: flex;
    align-items: center;
}
.menu-button-div{
    margin-right: 8px;
}
.drawer-links{
    width: 30vw;
    height: 100vh;
    padding: 2rem 3.6rem;
}
.drawer-links h2{
    margin-top: 2rem;
}
.drawer-links p{
    margin-top: 4.5rem;
    font-size: 24px;
    font-weight: 300;
    font-family: "DM Sans_18pt-ExtraLight";

}

.insideslider-links h6{
    font-family: "Epilogue", sans-serif;
    font-size: 42px;
    font-weight: 600;
    margin-top: 4vh;
}



.getInTouch-p{
    margin-top: 18px;
    margin-bottom: 5px;
    font-size: 16px !important;
    font-weight: 300;
    font-family: "DM Sans_18pt-ExtraLight";
    color: rgb(110, 110, 110);

}





@media only screen and (max-width: 700px) {
    .whatsappimgicon{
padding-right: 0px !important;
    }
    .getInTouch-p{
margin-top: 29px !important;
    }

    .nav-menu-inside-links{
        font-family: "Epilogue", sans-serif !important;
        font-weight: 600;
        margin-top: 3vh;
       font-size: 30px;
    }
    .insideslider-links h6{
        margin-top: 3vh;

      font-size: 30px;
    }

}
