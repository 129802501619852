
.webdev{
    width: 100%;
    height: 90vh;
    background-color: rgb(83, 83, 83);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: sticky;
    top: 0;
}
.webdevone{
  width: 100%;
  display: flex;
  background-color: rgb(204, 255, 159);
}
.webdevheading{
 width: 50%;
 height: 100%;
 background-color: white;
 color: black;
}
.webdevheading h1{
    margin: 0px 50px;
    margin-top: 90px;
    font-size: 46px;
}
.webdevheading p{
    width: 55%;
    margin: 25px 50px;
    font-size: 32px;
    font-family: 'DM Sans_18pt-ExtraLight' ;
    
}
.webdevheading button{
    margin: 10px 50px;
    width: 170px;
    height: 45px;
    background-color: white;
    color: black;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 40px;
}
.webdevimage{
    width: 50%;
    height: 100%;
}
.webdevimage img{
    width: 100%;
    height: 100%;
    background-size: cover;
}
.webdevtwo{
    width: 100%;
    
    background-color: rgb(138, 226, 255);
}
.webdevthree{
    width: 100%;
   
    background-color: rgb(255, 146, 146);
}
.webdevfour{
    width: 100%;
   
    background-color: rgb(239, 195, 133);
}