.Blogs-section{
    width: 100%;
    height: 85vh;
    background-color: #181818;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.blog-heading-section{
    margin-top: 10vh;
    margin-bottom: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 10vh;
    /* background-color: white; */
}
.blog-heading h1{
    color: white;
    font-size: 55px;
    font-weight: 500;
}
.blog-button button{
    width: 180px;
    height: 50px;
    background-color: #181818;
    color: white;
    font-size: 16px;
    border: 1px solid white;
    border-radius: 50px;
}
.blog-content{
    display: flex;
    margin-top: 2rem;
    gap: 2rem;
    width: 90%;
    height: 80%;
    /* background-color: white; */
    justify-content: space-between;
}
.blog-one{
    padding: 0.5rem;
    width: 50%;
    height: 85%;
    background-color: #181818;
    border: 1px solid rgba(255, 255, 255, 0.39);
}
.blog-two{
    padding: 0.5rem;
    width: 50%;
    height: 85%;
    background-color: #181818;
    border: 1px solid rgba(255, 255, 255, 0.39);
}
.blog-inside{
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #181818;
}
.blog-one-img{
    width: 100%;
    height: 100%;
}
.blog-one-content{
    height: 100%;
}
.blog-one-content p{
    font-family: DM Sans;
    font-weight: 400;
    margin: 20px 40px;
    font-size: 16px;
    margin-top: 30px;
    color: rgb(233, 233, 233);
}
.blog-one-content h3{
    margin: 15px 40px;
    color: white;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    width: 70%;
}

.blog-one-content button{
    margin: 5px 40px;
    width: 150px;
    height: 40px;
    border: 1px solid white;
    border-radius: 20px;
    background-color: #181818;
    color: white;
}
.blog-one-img img{
    width: 100%;
    height: 100%;
    background-size: cover;
}