
.Portfoliopageheading{
    width: 100%;
    height: 40vh;
    background-color: #181818;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.Portfoliopagebanner-heading{
    width: 90%;
    height: 20vh;
    margin-top: 10vh;
}
.Portfoliopage-para{
    display: flex;
    align-items: center;
}
.Portfoliopage-para p{
    font-size: 22px;
    font-family: "DM Sans_18pt-ExtraLight";
    color: #FFFFFF;

}
.Portfoliopage-dot{
    width: 11px;
    height: 11px;
    background-color: #FFFFFF;
    border-radius: 50%;
    margin-right: 10px;
}
.Portfoliopagebanner-heading h1{
   color: #FFFFFF;
   font-size: 70px;
   font-weight: 600;
   width: 100%;
   margin-top: 1rem;
   
}
.portfoliotabswidth{
   
}


.css-ttwr4n{
    background-color: #181818;
    width: 0px !important;
    height: 0px !important;
}

.All-Section{
    display: flex;
    width: 100%;
    /* flex-direction: column; */
    /* background-color: white; */
    justify-content: center;
}
.images-section{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 95%;
    /* background-color: blue; */
}
.Portfolio-images-section{
   width: 380px;
   height: 380px;
  
   background-color: #8d8d8d;
    overflow: hidden;
    
}

.Portfolio-images-section img{
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    transition: transform  0.4s;
   
}
.Portfolio-images-section img:hover{
    transform: scale(1.1);
}
.Portfolio-images-section img{
    width: 100%;
}
.Portfolio-images-section p{
    margin-top: 2vh;
}

.images-section img{
    /* margin-top: 5vh; */
}
.images-section p{
    color: white;
    font-family: "DM Sans18ptLight";
    font-size: 25px;
    font-weight: 400;
}
.Portfolio-Filter-Section{
    display: flex;
    /* flex-wrap: wrap; */
    overflow: hidden;
    margin: 0 auto;
    justify-content: center;
    /* background-color: #373737; */
    height: 25vh;
    width: 100%;
    padding: 0px 20px;
    /* background-color: #FFFFFF; */
}

.portfoliotabsbottom{
    width: 100%;
    height: 10vh;

    background-color: #181818;
}
.css-1aquho2-MuiTabs-indicator{
    display: none;
    
}



@keyframes fade-in-animation {
    0% {
      opacity: 0;
      transform: translateX(-20px); /* Initial position */
    }
    100% {
      opacity: 1;
      transform: translateX(0); /* Final position */
    }
  }
  
  .fade-in {
    animation: fade-in-animation 1s cubic-bezier(0.42, 0, 0.58, 1); /* cubic-bezier timing function */
  }
  

  @media only screen and (max-width: 700px) {

    .portfoliotabsbottom{
        width: 100%;
        height: 0vh;
    
        background-color: #181818;
    }
    .firsttablist,.secondtablist,.thirdtablist,.fourthtablist,.fifthtablist,.sixtablist{
        text-align: center;
        /* display: block; */
        width: 100%;
    }

    .Portfolio-images-section {
        width: 100%;
        height: 295px;
    }

    .css-heg063-MuiTabs-flexContainer{
        /* justify-content: center !important; */
    }

    .Portfoliopageheading{
        padding-left: 16px;
        height: 25vh;
    }

    .Portfoliopagebanner-heading h1{
        font-size: 24px;
    }
    
    .images-section img {
      width: 100%;
    }
    
    .Portfolio-Filter-Section{
       height: 45vh;
    }

    .css-13xfq8m-MuiTabPanel-root{
        padding: 15px;
    }
    
    .Portfolio-Filter-Section .MuiTab-root {
        font-size: 15px;
        padding: 0px;
        min-width: 50px;
      }
  }
  
  .tabHeadsection{
    display: flex;
    flex-wrap: wrap;
    /* background-color: rgba(137, 137, 137, 0.075); */
    height: 20vh;
    padding: 2vh 0vh;
  }
  